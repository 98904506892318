import React, { useState, useEffect } from "react"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import TitleUnderline from "./titleUnderline"

import img1 from "@components/pageScore/pagePrice/images/marcas/Beex.png"
import img2 from "@components/pageScore/pagePrice/images/marcas/Genesys.png"
import img3 from "@components/pageScore/pagePrice/images/marcas/InConcert.png"
import img4 from "@components/pageScore/pagePrice/images/marcas/Avaya.png"
import img5 from "@components/pageScore/pagePrice/images/marcas/Talkdesk.png"
import img6 from "@components/pageScore/pagePrice/images/marcas/Five9.png"
import img7 from "@components/pageScore/pagePrice/images/marcas/Twilio.png"


const ComparePrice = ({ data }) => {
  //funcion para slider de logos clientes
  var settings = {
    slidesToShow: 1,
    infinite: false,
    initialSlide: 0,
    dots: true,
    speed: 500,
    arrows: true,
  }

  const features = [
    <img key={3} src={img2} alt="Descripción de img2" className="image-compare-marcas" />,
    <img key={4} src={img3} alt="Descripción de img3" className="image-compare-marcas" />,
    <img key={5} src={img4} alt="Descripción de img4" className="image-compare-marcas" />,
    <img key={6} src={img5} alt="Descripción de img5" className="image-compare-marcas" />,
    <img key={7} src={img6} alt="Descripción de img6" className="image-compare-marcas" />,
    <img key={8} src={img7} alt="Descripción de img7" className="image-compare-marcas" />,
  ];

  const [windowsWidth, setWindowsWidth] = useState(true)
  useEffect(() => {
    let windowWidth = window.screen.width
    if (windowWidth < 1000) {
      setWindowsWidth(false)
    } else {
      setWindowsWidth(true)
    }
  }, [])

  const [fixedCabecera, setFixedCabecera] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      console.log("scrollY:", window.scrollY);
      if (window.scrollY >= 600) {
        setFixedCabecera(false);
      } else if (window.scrollY >= 230) {
        setFixedCabecera(true);
      } else {
        setFixedCabecera(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup function
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  return (
    <section className=" container-pages-price-score container-price-ticker container-price-score">

      <p className="container-price-score-title" style={{ marginBottom: '40px' }}>
        <TitleUnderline underline="Precios" /> de Beex Contact Center
      </p>
      <p className="container-compare-marca-description">
        Beex brinda los mejores costos del mercado acompañado de la mejor tecnología omnicanal potenciada con IA. Mira esta comparativa con otras marcas.
      </p>

      <div style={{ padding: '0px' }} className="container-boxprice-ticker">
        <div
          className={`${fixedCabecera && windowsWidth ? "container-boxprice-ticker-true" : " "
            }`}
        >
          <div className="cabecera-price-ticker" style={{ background: "#ffe7ba", paddingTop: '20px', paddingBottom: '20px' }}>
            <div className="container-title-caracteristic-price-score">
              <div className='container-price-ticker__item'>
                <p><strong> Plataforma</strong></p>
              </div>
            </div>
            <div className="container-price-score__containerfeatures__features container-price-ticker__containerfeatures__features" style={{ display: 'flex' }}>
              {data.map(elemento => {
                return (
                  <div
                    className={`container-price-score__containerfeatures__features-detail container-price-ticker__containerfeatures__features-detail`}
                    key={elemento}
                  >
                    <div>
                      <div
                        className={`container-price-ticker__containerfeatures__features-detail--info`}
                      >
                        <p style={{ wordWrap: 'break-word', padding: '0px 20px' }}>
                          <strong>   {elemento.title}</strong>
                        </p>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>


          </div>
          <section className="container-price-ticker__containerfeatures" >
            <div className="container-title-caracteristic-price-score">
              <div className='container-price-ticker__item'>
                <img src={img1} alt="Descripción de img1" className="image-compare-marcas" style={{ display: 'block' }} />
              </div>
            </div>
            <div className="container-price-score__containerfeatures__features container-price-ticker__containerfeatures__features" style={{ display: 'flex' }}>
              {data.map(elemento => {
                return (
                  <div
                    className={`container-price-score__containerfeatures__features-detail container-price-ticker__containerfeatures__features-detail`}
                    key={elemento}
                  >
                    <div>
                      <div
                        className={`container-price-ticker__containerfeatures__features-detail--info`}
                      >
                        {elemento.product}
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </section>



          {/* <div className="cabecera-price-ticker">
            <img src={img1} alt="Descripción de img1" className="image-compare-marcas" style={{ display: 'block' }} />
            <div className="container-price-score__containerfeatures__features container-price-ticker__containerfeatures__features" style={{ display: 'flex' }} >
              {data.map((elemento, index) => {
                return (
                  <div
                    className={`container-price-score__containerfeatures__features-detail container-price-ticker__containerfeatures__features-detail`}
                    key={elemento}
                    style={{ marginLeft: index === 0 ? "-27px" : '0px' }}
                  >

                    <div>
                      <div
                        className={`container-price-ticker__containerfeatures__features-detail--info`}

                        key={index}
                      >
                        {elemento.product}
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div> */}
        </div>
        <div className={`${fixedCabecera && windowsWidth ? "container-boxprice-compare-marcas-true" : " "}`} />

        <section className="container-price-ticker__containerfeatures" style={{ paddingTop: fixedCabecera && windowsWidth ? '330px' : "" }}   >
          {caracteristica(features, windowsWidth)}
          <div className="container-price-score__containerfeatures__features container-price-ticker__containerfeatures__features" style={{ display: 'flex' }}>
            {data.map(elemento => {
              return (
                <div
                  className={`container-price-score__containerfeatures__features-detail container-price-ticker__containerfeatures__features-detail`}
                  key={elemento}
                >
                  {Detail(elemento, windowsWidth)}
                </div>
              )
            })}
          </div>
        </section>
      </div >
    </section >
  )
}
export default ComparePrice

const Detail = elemento => {
  return (
    <div>
      {elemento.info.map((item, index) => {
        return (
          <div
            className={`container-price-ticker__containerfeatures__features-detail--info ${index % 2 === 0
              ? "container-price-ticker__item-blue"
              : ""
              } `}
            key={index}
          >
            {item}
          </div>
        )
      })}
    </div>
  )
}

const caracteristica = features => {
  return (
    <div className="container-title-caracteristic-price-score">
      {features.map((item, index) => (
        <div
          className={`container-price-ticker__item ${index % 2 === 0
            ? "container-price-ticker__item-blue"
            : "blanco"
            } `}
          key={index}
        >
          {item}
        </div>
      ))}
    </div>
  )
}
